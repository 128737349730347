import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import User from "./User";
import NFTs from "./myNFTs";
import Brands from "./brands";
import CustomHooks from "../../../hooks";
import useService from "../../../../profile/useService";
import UI from "../../index";
import Wallet from "./wallet";
import ExtraButtons from "./ExtraButtons";
import Auth from "../../../auth";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import LanguageSwitcher from "../languageSwitcher";
import menuBannerImg from "../../../../static/menu-banner.png";
import Grid from "@mui/material/Grid";
import qvrseLogo from "../../../../static/qvrse-logo.png";
import collectionsIcon from "../../../../static/collection-icon-dark.png";
import brandsIcon from "../../../../static/brands-icon.png";
import EditIcon from "@mui/icons-material/Edit";
import marketplaceIcon from "../../../../static/marketplace_icon.png";
import LogInDialogFlow from "../../../../profile/dialogs/LogInDialogFlow";
import { useUserContext } from "../../../userContext";
import Presale from "./Presale";
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
    borderRadius: "0",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.dialog.background
        : "rgba(255, 255, 255, 0.7) !important",
    backdropFilter:
      theme.palette.type === "dark"
        ? theme.palette.dialog.backdropFilter
        : "blur(20px)",
  },
}));

const GridItem = ({ url, name, icon }) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Box
      onClick={() => history.push(url)}
      sx={{
        height: "60px",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        p: "16px",
        boxShadow: (theme) => theme.palette.dialog.boxShadow,
        background:
          theme.palette.type === "dark"
            ? "rgba(255, 255, 255, 0.06)"
            : "rgba(255, 255, 255, 0.16)",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component={"img"}
          src={icon}
          sx={{
            width: "20px",
            mr: "10px",
            filter: theme.palette.type === "dark" ? "invert(1)" : "unset",
          }}
        />
        <Typography variant={"subtitle2"} sx={{ fontSize: "13px !important" }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

const Index = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const service = useService();
  const { logout } = Auth.useAuth();
  const history = useHistory();
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const { user: loggedInUser } = useUserContext();
  //const [open, setOpen] = React.useState(false);

  const {
    data: userData,
    isLoading: loadingProfile,
    refetch,
  } = CustomHooks.useFetch(["user_profile_data"], service.info, {
    enabled: false,
  });

  //if menu open, fetch info data
  useEffect(() => {
    if (open) {
      refetch();
    }
    // eslint-disable-next-line
  }, [open]);

  React.useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [location]);

  // React.useEffect(() => {
  //   setOpen(open);
  //   // eslint-disable-next-line
  // }, [open]);
  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={"right"}
      open={open}
      onClose={onClose}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <Box
        sx={{
          display: "flex",
          px: "24px",
          mt: "24px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px !important",
            color: (theme) => theme.palette.text.primary,
          }}
        >
          MENU
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LanguageSwitcher />
            <UI.ThemeSwitcher />
          </Box>
          <IconButton onClick={() => onClose()}>
            <CloseIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: "24px",
                "&:hover": {
                  transition: "all 0.1s ease-in-out",
                  transform: "scale(1.1) rotate(-90deg)",
                },
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ px: "24px", mt: "24px" }}>
        <Link
          to={{
            pathname: "https://token.qvrse.io/",
          }}
          target="_blank"
        >
          <img
            src={menuBannerImg}
            alt="menu banner"
            style={{ width: "100%" }}
          />
        </Link>
      </Box>
      <Box sx={{ mx: "24px", mt: "6px" }}>
        <Grid container spacing={1}>
          <Grid item mobile={6} sx={{ pt: "6px !important" }}>
            <Box
              onClick={() => history.push("/")}
              sx={{
                height: "60px",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                p: "15px",
                boxShadow: (theme) => theme.palette.dialog.boxShadow,
                background:
                  theme.palette.type === "dark"
                    ? "rgba(255, 255, 255, 0.06)"
                    : "rgba(255, 255, 255, 0.16)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  component={"img"}
                  src={qvrseLogo}
                  sx={{
                    width: "28px",
                    mr: "6px",
                    ml: "-3px",
                  }}
                />
                <Typography
                  variant={"subtitle2"}
                  sx={{ fontSize: "13px !important" }}
                >
                  {t("appBar.home")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            mobile={6}
            sx={{ pt: "6px !important", pl: "6px !important" }}
          >
            <GridItem
              name={t("appBar.brands")}
              icon={brandsIcon}
              url={"/brand/explore"}
            />
          </Grid>
          <Grid item mobile={6} sx={{ pt: "6px !important" }}>
            <GridItem
              name={t("appBar.collections")}
              icon={collectionsIcon}
              url={"/collection/explore"}
            />
          </Grid>
          <Grid
            item
            mobile={6}
            sx={{ pt: "6px !important", pl: "6px !important" }}
          >
            <Box
              onClick={() => history.push("/brand/apply")}
              sx={{
                height: "60px",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                boxShadow: (theme) => theme.palette.dialog.boxShadow,
                p: "13px",
                background:
                  theme.palette.type === "dark"
                    ? "rgba(255, 255, 255, 0.06)"
                    : "rgba(255, 255, 255, 0.16)",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EditIcon
                  sx={{
                    width: "22px",
                    mr: "7px",
                  }}
                />
                <Typography
                  variant={"subtitle2"}
                  sx={{ fontSize: "13px !important" }}
                >
                  {t("appBar.userMenu.createBrand")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/*<Grid item mobile={6} sx={{ pt: "6px !important" }}>*/}
          {/*  <Box*/}
          {/*    onClick={() => history.push("/marketplace")}*/}
          {/*    sx={{*/}
          {/*      height: "60px",*/}
          {/*      display: "flex",*/}
          {/*      alignItems: "center",*/}
          {/*      borderRadius: "5px",*/}
          {/*      boxShadow: (theme) => theme.palette.dialog.boxShadow,*/}
          {/*      p: "15px",*/}
          {/*      background:*/}
          {/*        theme.palette.type === "dark"*/}
          {/*          ? "rgba(255, 255, 255, 0.06)"*/}
          {/*          : "rgba(255, 255, 255, 0.16)",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Box sx={{ display: "flex", alignItems: "center" }}>*/}
          {/*      <Box*/}
          {/*        component={"img"}*/}
          {/*        src={marketplaceIcon}*/}
          {/*        sx={{*/}
          {/*          filter: theme.palette.type === "dark" ? "invert(1)" : "unset",*/}
          {/*          width: "19px",*/}
          {/*          mr: "9px",*/}
          {/*        }}*/}
          {/*      />*/}
          {/*      <Typography*/}
          {/*        variant={"subtitle2"}*/}
          {/*        sx={{ fontSize: "13px !important" }}*/}
          {/*      >*/}
          {/*        {t("appBar.marketplace")}*/}
          {/*      </Typography>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          <Grid
            item
            mobile={6}
            sx={{ pt: "6px !important", pl: "6px !important" }}
          >
            <Link
              to={{
                pathname: "https://token.qvrse.io/",
              }}
              target="_blank"
            >
              <Box
                sx={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px",
                  boxShadow: (theme) => theme.palette.dialog.boxShadow,
                  p: "15px",
                  background:
                    theme.palette.type === "dark"
                      ? "rgba(255, 255, 255, 0.06)"
                      : "rgba(255, 255, 255, 0.16)",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Presale />
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      ml: "3px",
                      fontSize: "13px !important",
                    }}
                  >
                    Token
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
      {!loadingProfile && loggedInUser && userData && (
        <DialogTitle
          className="drawerTitle"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background:
              theme.palette.type === "dark"
                ? "rgba(255, 255, 255, 0.12)"
                : "rgba(255, 255, 255, 0.24)",
            p: "18px 6px 18px 18px",
            m: "20px 24px",
            borderRadius: (theme) => theme.borderRadius.primary,
            boxShadow: (theme) => theme.palette.dialog.boxShadow,
          }}
        >
          <User />
        </DialogTitle>
      )}
      <Box sx={{ px: "24px", width: "auto", zIndex: 0 }} role="presentation">
        {loadingProfile && loggedInUser && <UI.Busy.Indicator />}
        {loggedInUser && userData && (
          <Box sx={{ mb: "30px" }}>
            <NFTs collections={userData.owned_nfts} />
            <Wallet refetchUser={refetch} />
            <Brands brands={userData.my_brands} />
            <ExtraButtons />
            <UI.Button
              sx={{ mt: "25px" }}
              type={"primary"}
              title={t("appBar.logout")}
              onClick={() => logout()}
            />
          </Box>
        )}

        {!loggedInUser && (
          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "24px",
              right: "24px",
            }}
          >
            <ExtraButtons />
            <UI.Button
              sx={{ mt: "25px" }}
              type={"primary"}
              title={t("appBar.logIn")}
              onClick={() => setTriggerLoginDialog(Math.random())}
            />
          </Box>
        )}
      </Box>
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onLogin={() => {
          refetch();
          setTriggerLoginDialog(null);
        }}
        onClose={() => setTriggerLoginDialog(null)}
      />
    </Drawer>
  );
};

export default Index;
