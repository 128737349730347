import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Logo from "../../../static/qvrse-logo.png";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import MenuIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import UI from "../";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileMenu from "./MobileMenu";
import RegisterDialog from "../../../profile/dialogs/RegisterDialog";
import EmailConfirmationDialog from "../../../profile/dialogs/EmailConfirmation";
import { useUserContext } from "../../userContext";
import useUserService from "../../../profile/useService";
import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";
import QvrsButton from "../Button";
import LogInDialogFlow from "../../../profile/dialogs/LogInDialogFlow";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import CreateSmartAccountDialog from "../../../profile/dialogs/CreateSmartAccountDialog";
import LoginIcon from "@mui/icons-material/Login";
import UserSideMenu from "./userSideMenu";
import NotificationsSideMenu from "./notificationsSideMenu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";

const MaterialAppBar = (props) => {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userService = useUserService();
  const { user: loggedInUser, setUser: setUserContext } = useUserContext();
  const location = useLocation();
  const [triggerMenuOpen, setTriggerMenuOpen] = React.useState(null);
  const [triggerUserSideMenuOpen, setTriggerUserSideMenuOpen] =
    React.useState(false);
  const [
    triggerNotificationsSideMenuOpen,
    setTriggerNotificationsSideMenuOpen,
  ] = React.useState(false);
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);

  const showroomMatch = useRouteMatch("/brand/:brandId/showroom");
  const isShowRoomPage = showroomMatch !== null;

  const isExploreMainPage = location.pathname === "/";

  React.useEffect(() => {
    if (i18n && loggedInUser) {
      i18n.changeLanguage(loggedInUser.user_language);
    }
  }, [loggedInUser, i18n]);

  React.useEffect(() => {
    const fetchData = async () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        await userService
          .basic_info()
          .then(async (response) => {
            setUserContext(response);
            if (response.smart_address === null) {
              setShowSmartAccountDialog(true);
            }
            props.onThemeChange(null, response.user_theme === "dark");
          })
          .catch(() => {});
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        display: isShowRoomPage === true ? "none" : "block",
      }}
    >
      <AppBar
        sx={{
          borderRadius: "0px",
          paddingLeft: {
            xs: "0px",
            md: "15px",
            lg: "15px",
            mobile: "10px",
          },
          paddingRight: {
            xs: "0px",
            md: "15px",
            lg: "15px",
            mobile: "10px",
          },
          height: "72px",
          zIndex: 1000,
          width: "100%",
          left: 0,
          right: 0,
          margin: "auto",
          backgroundColor: (theme) => theme.palette.appbar.background,
          color: (theme) => theme.palette.appbar.text,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          justifyContent: "center",
        }}
      >
        <Toolbar
          sx={{ justifyContent: "space-between", height: "72px", px: "0px" }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              onClick={() =>
                history.push({
                  pathname: "/",
                  state: { scrollToTop: true },
                })
              }
              sx={{
                mr: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <img src={Logo} width={"42px"} height={"42px"} alt={"logo"} />
                <Box
                  sx={{
                    display: { mobile: "flex", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "center",
                    ml: "2.5px",
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant={"h4"}>QVRSE</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!isMobile && !loggedInUser && (
              <Box sx={{ display: "flex" }}>
                <UI.Button
                  data-test={"login-button"}
                  sx={{
                    width: "unset",
                    mr: "9px",
                    ml: "3px",
                    fontSize: "14px",
                    transition: "all 0.2s ease-in-out",
                    color: (theme) => theme.palette.text.primary,
                    "&:hover": {
                      color: (theme) => theme.palette.text.secondary,
                    },
                  }}
                  type={"tertiary"}
                  onClick={() => setTriggerLoginDialog(Math.random())}
                  title={t("appBar.logIn")}
                  startIcon={<LoginIcon sx={{ fontSize: "1.35rem" }} />}
                />
                <UI.Button
                  data-test={"create-account-button"}
                  sx={{
                    height: "42px",
                    fontSize: "14px",
                    padding: "5px 16px",
                    width: "unset",
                  }}
                  type={"primary"}
                  onClick={() => setTriggerRegisterDialog(Math.random())}
                  title={t("appBar.createAccount")}
                />
              </Box>
            )}
            {!isMobile && loggedInUser && (
              <>
                <Box sx={{ display: "flex", ml: "18px" }}>
                  {/*{loggedInUser.address && (*/}
                  {/*  <ConnectWallet*/}
                  {/*    onProfileClick={() => setTriggerMenuOpen(Math.random())}*/}
                  {/*  />*/}
                  {/*)}*/}
                  {loggedInUser.smart_address === null && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                        mr: "10px",
                      }}
                    >
                      <QvrsButton
                        onClick={() => {
                          setShowSmartAccountDialog(Math.random());
                        }}
                        sx={{
                          height: "42px",
                          fontSize: "14px",
                        }}
                        title={t("commons.secureAccount")}
                        type={"secondary"}
                      />
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: "flex" }}>
                  <UserAvatar user={loggedInUser} />
                </Box>
              </>
            )}

            {isMobile && (
              <>
                {loggedInUser && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/*<Avatar*/}
                    {/*  onClick={() => setTriggerUserSideMenuOpen(true)}*/}
                    {/*  sx={{*/}
                    {/*    mr: "5px",*/}
                    {/*    transition: "box-shadow 0.3s",*/}
                    {/*    width: "30px",*/}
                    {/*    height: "30px",*/}
                    {/*    boxShadow:*/}
                    {/*      loggedInUser?.email_verified &&*/}
                    {/*      loggedInUser?.smart_address !== null*/}
                    {/*        ? "unset"*/}
                    {/*        : "0 0 0 2px #d32f2f",*/}
                    {/*    "&:hover": {*/}
                    {/*      boxShadow:*/}
                    {/*        loggedInUser?.email_verified &&*/}
                    {/*        loggedInUser?.smart_address !== null*/}
                    {/*          ? "0 0 0 2px #944DFF"*/}
                    {/*          : "0 0 0 2px #d32f2f",*/}
                    {/*    },*/}
                    {/*  }}*/}
                    {/*  src={loggedInUser?.avatar_url}*/}
                    {/*/>*/}
                    {/*<Box*/}
                    {/*  sx={{ p: "12px 4px 12px 12px" }}*/}
                    {/*  onClick={() => setTriggerNotificationsSideMenuOpen(true)}*/}
                    {/*>*/}
                    {/*  <Badge badgeContent={2} color={"error"}>*/}
                    {/*    <NotificationsIcon*/}
                    {/*      sx={{*/}
                    {/*        color: (theme) => theme.palette.text.primary,*/}
                    {/*        width: "22px",*/}
                    {/*        mb: "2px",*/}
                    {/*        mr: "-2px",*/}
                    {/*      }}*/}
                    {/*      alt="notification"*/}
                    {/*    />*/}
                    {/*  </Badge>*/}
                    {/*</Box>*/}
                  </Box>
                )}
                <IconButton
                  sx={{ display: { mobile: "flex", md: "none" } }}
                  onClick={() => setTriggerUserSideMenuOpen(Math.random())}
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                >
                  <MenuIcon
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "25px",
                      "&:hover": {
                        transition: "all 0.1s ease-in-out",
                        transform: "scale(1.2)",
                      },
                    }}
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <MobileMenu
        triggerMenuOpen={triggerMenuOpen}
        onLogin={() => {
          setTriggerMenuOpen(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
        onRegister={() => setTriggerRegisterDialog(Math.random())}
      />
      {/*{userSideMenu(triggerUserSideMenuOpen, setTriggerUserSideMenuOpen)}*/}
      {/*<UserSideMenu toggleMenuOpen={triggerUserSideMenuOpen} />*/}
      <UserSideMenu
        open={triggerUserSideMenuOpen}
        onClose={() => setTriggerUserSideMenuOpen(false)}
      />
      <NotificationsSideMenu
        open={triggerNotificationsSideMenuOpen}
        onClose={() => setTriggerNotificationsSideMenuOpen(false)}
      />
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => setTriggerEmailConfirmation(Math.random())}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
        onGoogleRegister={(smartAccountAddress) => {
          if (smartAccountAddress === null) {
            setShowSmartAccountDialog(true);
          }
        }}
      />
      <CreateSmartAccountDialog toggleOpen={showSmartAccountDialog} />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />
      <Toolbar
        sx={{ height: "90px", display: isExploreMainPage ? "none" : "block" }}
        variant="dense"
      />
    </Box>
  );
};

export default MaterialAppBar;
