import React from "react";
import { Controller } from "react-hook-form";
import UI from "../../../../../@components/UI";
import { Box } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import PercentIcon from "@mui/icons-material/Percent";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
const Royalty = ({ brandFeeAddress, control }) => {
  const history = useHistory();

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant={"h4"}>Royalty Fee</Typography>
          <UI.Tooltip>
            <Typography variant={"subtitle3"}>
              Set the royalty fee for your NFT collection. When users sell an
              NFT, the fee will be automatically deducted from the sale price
              and sent to the wallet specified in the settings.
            </Typography>
          </UI.Tooltip>
        </Box>
        <Controller
          name={"royalty.fee"}
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <UI.TextField
              disabled={brandFeeAddress === ""}
              placeholder={"0"}
              {...field}
              endAdornment={
                <InputAdornment position="start">
                  <PercentIcon
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  />
                </InputAdornment>
              }
            />
          )}
        />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant={"h4"}>Fee Address</Typography>
          <UI.Tooltip>
            <Typography variant={"subtitle3"}>
              The wallet address where the royalty fee will be sent.
            </Typography>
          </UI.Tooltip>
        </Box>
        <Controller
          name={"royalty.fee_address"}
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <UI.TextField disabled placeholder={"0x"} {...field} />
          )}
        />
        {brandFeeAddress === "" && (
          <Typography
            variant={"subtitle2"}
            sx={{
              mt: "20px",
              display: "flex",
              mb: "0px",
            }}
          >
            <Alert variant="outlined" severity="info">
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                {`In order to receive royalty fees, you need to set crypto wallet address in the`}{" "}
                <Box
                  sx={{
                    color: (theme) => theme.palette.text.linkBlue,
                    textDecorationColor: (theme) => theme.palette.text.linkBlue,
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    ml: "5px",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      color: (theme) => theme.palette.text.linkBlueHover,
                      textDecorationColor: (theme) =>
                        theme.palette.text.linkBlueHover,
                    },
                  }}
                  onClick={() => history.push(`/brand/213/settings/payment`)}
                  component={"span"}
                >
                  settings
                </Box>
              </Box>
            </Alert>
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Royalty;
