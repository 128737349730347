import React from "react";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Featured from "./featured";
import CollectionsSlider from "./collectionSlider";
import Nft101 from "./nft101";
import Promotion from "./promotion";
import promotionBanner from "../static/slikaizpostavit.png";
import promotionBanner2 from "../static/album.png";
import News from "./news";
import Trending from "./trending";
import UI from "../@components/UI";
import CustomHooks from "../@components/hooks";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import RedirectToProfileDialog from "./RedirectToProfileDialog";
import { useTranslation } from "react-i18next";

const PromotionItem = ({ text }) => {
  return (
    <Box component={"span"} sx={{ display: "flex" }}>
      <DoubleArrowIcon sx={{ mr: "8px", color: "#944dff", width: "18px" }} />
      {text}
    </Box>
  );
};

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const location = useLocation();
  const [backgroundImage, setBackgroundImage] = React.useState("");

  const [emailRedirectDialogText, setEmailRedirectDialogText] =
    React.useState(null);

  const [emailRedirectToProfile, setEmailRedirectToProfile] =
    React.useState(null);

  React.useEffect(() => {
    const email_redirect = query.get("email_redirect");
    const email_redirect_profile = query.get("email_redirect_profile");
    if (email_redirect) setEmailRedirectDialogText(email_redirect);
    if (email_redirect_profile)
      setEmailRedirectToProfile(email_redirect_profile);
  }, [query]);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        px: "0px",
      }}
    >
      <Box
        component={"img"}
        src={backgroundImage}
        sx={{
          position: "absolute",
          top: {md: "-100px", mobile: "110px"},
          maxHeight: "600px",
          minHeight: "400px",
          filter: "blur(100px)",
          width: "100%",
          opacity: {md: "0.5", mobile: "0.75"},
        }}
      />
      {emailRedirectDialogText && (
        <UI.InfoDialog
          contentText={emailRedirectDialogText}
          onClose={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {emailRedirectToProfile && (
        <RedirectToProfileDialog
          contentText={emailRedirectToProfile}
          onClose={() => {
            setEmailRedirectToProfile(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectToProfile(null);
            history.push(`/profile`);
          }}
        />
      )}
      <Box
        sx={{
          paddingLeft: { md: "80px", mobile: "0px" }, //SideMenu width
          paddingTop: { md: "50px", mobile: "10px" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            px: { lg: "100px", md: "50px", mobile: "10px" },
            mt: { md: "0px", mobile: "100px" },
          }}
        >
          <Featured
            onCurrentSlideImage={(imageUrl) => setBackgroundImage(imageUrl)}
          />
        </Box>
        <Box>
          <Box
            sx={{
              px: { lg: "100px", md: "50px", mobile: "10px" },
              mt: { md: "50px", mobile: "40px" },
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                mb: { md: "38px", mobile: "22px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: {
                    md: "30px !important",
                    mobile: "24px !important",
                  },
                  textAlign: { md: "center", mobile: "center" },
                }}
              >
                {t("explore.trendingCollections.title")}
              </Typography>
            </Box>
            <CollectionsSlider
              onClick={(collection) => {
                history.push({
                  pathname: `/brand/${collection.dao.id}/collection/${collection.id}`,
                  state: { scrollToTop: true },
                });
              }}
            />
          </Box>

          <Box
            sx={{
              px: { lg: "100px", md: "50px", mobile: "10px" },
              mt: { md: "50px", mobile: "40px" },
            }}
          >
            <Box>
              <Promotion
                isExteranlUrl={true}
                image={promotionBanner}
                title={t("explore.promotion1.title")}
                subtitle={t("explore.promotion1.subtitle")}
                // content={
                //   <Box sx={{ display: "flex", flexDirection: "column" }}>
                //     <PromotionItem
                //       text={t("explore.promotion1.promotionItem1")}
                //     />
                //     <PromotionItem
                //       text={t("explore.promotion1.promotionItem2")}
                //     />
                //     <PromotionItem
                //       text={t("explore.promotion1.promotionItem3")}
                //     />
                //     <PromotionItem
                //       text={t("explore.promotion1.promotionItem4")}
                //     />
                //     <PromotionItem
                //       text={t("explore.promotion1.promotionItem5")}
                //     />
                //   </Box>
                // }
                url={
                  "https://www.spatial.io/s/QvrseStation-6655c097311b7a21439823eb?share=3953462147396508479"
                }
              />
            </Box>
            <Box sx={{ mt: "30px" }}>
              <Promotion
                isExteranlUrl={false}
                reverse={true}
                image={promotionBanner2}
                title={t("explore.promotion2.title")}
                subtitle={t("explore.promotion2.subtitle")}
                content={
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem1")}
                    />
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem2")}
                    />
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem3")}
                    />
                    <PromotionItem
                      text={t("explore.promotion2.promotionItem4")}
                    />
                  </Box>
                }
                url={"/brand/42/album/3"}
              />
            </Box>
          </Box>

          <Box
            sx={{
              px: { lg: "100px", md: "50px", mobile: "10px" },
              mt: { md: "60px", mobile: "40px" },
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                mb: { md: "38px", mobile: "22px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: {
                    md: "30px !important",
                    mobile: "24px !important",
                  },
                  textAlign: { md: "center", mobile: "center" },
                }}
              >
                NFT 101
              </Typography>
            </Box>
            <Nft101 />
          </Box>

          <Box
            sx={{
              px: { lg: "100px", md: "50px", mobile: "10px" },
              mt: { md: "60px", mobile: "40px" },
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                mb: { md: "38px", mobile: "22px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: {
                    md: "30px !important",
                    mobile: "24px !important",
                  },
                  textAlign: { md: "center", mobile: "center" },
                }}
              >
                {t("explore.trendingBrands.title")}
              </Typography>
            </Box>
            <Trending
              onClick={(id) => {
                history.push({
                  pathname: id === 1 ? `/qvrse` : `/brand/${id}`,
                  state: { scrollToTop: true },
                });
              }}
            />
          </Box>

          <Box
            sx={{
              px: { lg: "100px", md: "50px", mobile: "10px" },
              mt: { md: "50px", mobile: "40px" },
            }}
          >
            <News />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Index;
