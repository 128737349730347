import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import AccordionItem from "./AccordionItem";
import UI from "../../UI";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles(() => ({
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  },

  thumb: {
    display: "inline-flex",
    borderRadius: 5,
    border: "1px solid transparent",
    marginBottom: 10,
    marginRight: 8,
    width: ({ isMobile }) => {
      return isMobile ? "60px" : "80px";
    },
    height: ({ isMobile }) => {
      return isMobile ? "60px" : "80px";
    },
    marginLeft: 4,
    boxSizing: "border-box",
    position: "relative",
    "&:hover $overlay": {
      opacity: 1,
      border: "1px solid #944dff",
      boxShadow: "#944dff 0px 0px 10px 0px",
      transition: "all 0.2s ease-in-out",
    },
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%",
    borderRadius: "5px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".5s ease",
    borderRadius: 5,
    backgroundColor: "rgba(8, 10, 25, 0.7)",
  },
}));

const ResponsiveVideo = ({ src }) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1100); // md breakpoint

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100); // md breakpoint
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const videoStyle = {
    maxHeight: isMobile ? "auto" : "600px",
    width: isMobile ? "100%" : "auto",
    margin: "0 auto",
  };

  return (
    <video playsInline loop autoPlay="autoplay" style={videoStyle}>
      <source src={src} />
    </video>
  );
};

const UtilityDialog = ({
  utility,
  currency,
  customStyles,
  onClose,
  gasFeesPayedBySystem,
  showCustomLinks,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ isMobile });

  const [showPreviewFile, setShowPreviewFile] = React.useState(null);
  const { t, i18n } = useTranslation();

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          background: customStyles
            ? customStyles.card_background_color
            : (theme) => theme.palette.colors.darkGray,
          padding: { md: "36px 30px", mobile: "36px 20px" },
          width: "700px",
          margin: { mobile: "0px 10px" },
        },
      }}
      open={utility !== null}
      onClose={onClose}
    >
      <DialogContent
        sx={{
          overflowY: { mobile: "auto", md: "hidden" }, // Enables scrolling only when needed
          p: { mobile: "0px" },
        }}
      >
        <CloseIcon
          onClick={() => {
            onClose();
          }}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <>
          <Box sx={{ mb: "6px", alignItems: "center" }}>
            <Typography variant={"h5"}>
              {utility?.name_slo !== null &&
              utility?.name_slo !== "" &&
              utility?.name_slo !== undefined &&
              i18n.language === "sl"
                ? utility?.name_slo
                : utility?.name || ""}
            </Typography>
          </Box>

          {utility?.description && (
            <Box sx={{ mb: "8px" }}>
              <UI.ReadMoreDescription
                sx={{
                  overflowWrap: "anywhere",
                  textTransform: "unset",
                  color: (theme) => theme.palette.text.secondary,
                  lineHeight: "1.45 !important",
                }}
                maxTextLength={300}
              >
                {utility?.description_slo !== null &&
                utility?.description_slo !== "" &&
                utility?.description_slo !== undefined &&
                i18n.language === "sl"
                  ? utility?.description_slo
                  : utility?.description || ""}
              </UI.ReadMoreDescription>
            </Box>
          )}

          {utility?.custom_info_fields?.map((field) => {
            if (showCustomLinks === false && field.value.startsWith("http")) {
              return false;
            }
            return (
              <AccordionItem
                isButton={
                  field?.type === "button" || field?.input_type === "button"
                }
                key={field?.id}
                title={field?.title}
                value={field?.value}
                type={field?.type || field?.input_type}
              />
            );
          })}

          {utility?.media && utility?.media.length > 0 && (
            <Box sx={{ mb: "5px" }}>
              <Typography variant={"subtitle3"}>
                {t("commons.media") + ":"}
              </Typography>
              <Box sx={{ display: "flex", mt: { md: "10px", mobile: "6px" } }}>
                {utility?.media.map((file, index) => (
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: isMobile ? "60px" : "80px",
                      height: isMobile ? "60px" : "80px",
                    }}
                    onClick={() => {
                      setShowPreviewFile(file);
                    }}
                    className={classes.thumb}
                    key={index}
                  >
                    {file?.file_type?.startsWith("image") && (
                      <Box className={classes.thumbInner}>
                        <Box
                          component={"img"}
                          src={file.image_url}
                          className={classes.img}
                        />
                      </Box>
                    )}
                    {file?.file_type?.startsWith("video") && (
                      <Box className={classes.thumbInner}>
                        <video
                          autoPlay="autoplay"
                          playsInline
                          loop
                          muted
                          className={classes.img}
                        >
                          <source src={file.image_url} />
                        </video>
                      </Box>
                    )}
                    <Box className={classes.overlay}>
                      <ZoomInIcon />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {currency && (
            <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
              <Typography variant={"subtitle3"}>
                {t("commons.price") + ":"}
              </Typography>
              <Box>
                <Box sx={{ display: "flex", ml: "10px", mt: "2px" }}>
                  {gasFeesPayedBySystem === false && (
                    <Box
                      sx={{
                        width: "18px",
                        height: "18px",
                        mr: "8px",
                        mt: "1px",
                      }}
                    >
                      <UI.PaymentIcon currency={currency} />
                    </Box>
                  )}
                  <Typography variant={"h8"}>
                    {utility?.price === 0 ? (
                      t("commons.free")
                    ) : (
                      <>
                        {utility?.price} {currency}
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </>
        <Dialog
          fullWidth
          onClose={() => setShowPreviewFile(null)}
          open={showPreviewFile}
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "max-content",
            },
          }}
          PaperProps={{
            maxWidth: "1200px",
          }}
        >
          {showPreviewFile && (
            <>
              {showPreviewFile?.file_type?.startsWith("image") && (
                <Box
                  component={"img"}
                  sx={{
                    maxHeight: { md: "600px", mobile: "auto" },
                    width: { md: "auto", mobile: "100%" },
                    margin: "0 auto",
                  }}
                  src={showPreviewFile.image_url}
                />
              )}
              {showPreviewFile?.file_type?.startsWith("video") && (
                <ResponsiveVideo src={showPreviewFile.image_url} />
              )}
            </>
          )}
        </Dialog>
      </DialogContent>
    </MaterialDialog>
  );
};

export default UtilityDialog;
