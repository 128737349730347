import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay } from "swiper/modules";
import "./styles.css";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";

const CollectionCard = ({ item }) => {
  const history = useHistory();
  const { t } = useTranslation();
  item?.nft_file_type === "" && (item.nft_file_type = "image/png");

  return (
    <Card
      onClick={() => {
        history.push({
          pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        maxWidth: 345,
        minHeight: "303.39px !important",
        margin: "auto",
        cursor: "pointer",
        background: (theme) => theme.palette.bgTransparent.primary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      {item?.nft_file_type.startsWith("image/") && (
        <CardMedia
          component="img"
          alt={item.name}
          height="180"
          image={item.nft_image_url}
        />
      )}
      {item?.nft_file_type.startsWith("video/") && (
        <CardMedia
          component="video"
          alt={item.name}
          height="180"
          sx={{ objectFit: "cover" }}
          src={item.nft_image_url}
          playsInline
          autoPlay
          muted
          loop
        />
      )}
      <CardContent
        sx={{
          p: "13px 15px 18px !important",
        }}
      >
        <Typography variant="subtitle4">{item.dao?.name}</Typography>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          sx={{ lineHeight: "1.3" }}
        >
          <UI.OverflowTip>{item.name}</UI.OverflowTip>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {item?.total_vat_amount_string !== "0.00" && (
            <Box sx={{ display: "block" }}>
              <Typography variant="subtitle4" sx={{ fontSize: "11px" }}>
                {t("commons.price")}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                    <UI.PaymentIcon currency={item?.currency} />
                  </Box>
                  <Typography variant="h8" sx={{ lineHeight: "1.2" }}>
                    {`${item?.total_price_with_tax}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {item?.total_price_with_tax === "0.00" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"h10"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 14px",
                  mt: "15px",
                  height: "22px",
                  width: "fit-content",
                  borderRadius: "100px",
                  backgroundColor: (theme) => theme.palette.tag.red,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {t("commons.free")}
              </Typography>
            </Box>
          )}

          {item.nft_amount_limit < 999999 && (
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "block", textAlign: "end" }}>
                <Typography variant={"subtitle4"} sx={{ fontSize: "11px" }}>
                  {t("commons.claimed")}
                </Typography>
                <Box sx={{ display: "flex", textAlign: "end" }}>
                  <Typography variant={"h8"} sx={{ lineHeight: "1.2" }}>
                    {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const CollectionSlider = ({ items }) => {
  return (
    <>
      <Swiper
        grabCursor={true}
        slidesPerView={1.2}
        spaceBetween={20}
        slidesPerGroup={1}
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        pagination={true}
        modules={[Autoplay]}
        breakpoints={{
          500: { slidesPerView: Math.min(2, items.length), spaceBetween: 20 },
          800: { slidesPerView: Math.min(3, items.length), spaceBetween: 20 },
          1280: { slidesPerView: Math.min(4, items.length), spaceBetween: 20 },
          1700: { slidesPerView: Math.min(5, items.length), spaceBetween: 20 },
        }}
        className="collection-slider"
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index}>
              <CollectionCard item={item} />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default CollectionSlider;
